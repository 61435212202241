import * as React from 'react'
import Welcome from "../Components/Welcome";
import Relevance from "../Components/Relevance";
import DiBEN from "../Components/DiBEN";
import {Benefits} from "../Components/Benefits";
import Examples from "../Components/Examples";
import Footer from "../Components/Footer";
import ContactMailIcon from '@mui/icons-material/ContactMail';
import {Fab} from "@mui/material";
const Spacer = () => {
    return <div style={{height: '3vh'}}/>
}

export function LandingPage() {
    return (
        <div>

            <Welcome/>
            <Spacer/>
            <Relevance/>
            <DiBEN/>
            <Spacer/>
            <Benefits/>

            <Spacer/>
            <Examples/>
            <Spacer/>
            <Footer/>

          <Fab variant="extended" href={'mailto:info@diben-hessen.de?subject=Ich Interessiere mich für Ihre Plattform"'}  sx={{
            backgroundColor:'#00a7f0',
                margin: 0,
                top: 'auto',
                right: 20,
                bottom: 20,
                left: 'auto',
                position: 'fixed'}}>
            <ContactMailIcon sx={{ mr: 1 }} />
            Kontakt
          </Fab>
        </div>
    )
}
