import {
  Box,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import BenchmarkingToolImage from "../Images/BenchmarkingToolImage.png";
import PraxisbeispieleImage from "../Images/PraxisbeispieleImage.png";
import VisualisierungstoolImage from "../Images/VisualisierungstoolImage.png";
import WissensbausteinImage from "../Images/WissensbausteinImage.png";

import { motion, useScroll, useSpring } from "framer-motion";
import * as React from "react";

const CustomCarousel = () => {
  const defaultSettings = {
    autoPlay: true,
    animation: "slide",
    indicators: true,
    interval: 6000,
    duration: 500,
    navButtonsAlwaysVisible: true,
    navButtonsAlwaysInvisible: false,
    cycleNavigation: true,
    fullHeightHover: true,
    swipe: true,
  };
  return (
    <div onClick={() => console.log("hi")}>
      <Carousel
        afterChange={(previousSlide, { currentSlide, onMove }) => {
          console.log(onMove);
        }}
        className="Example"
        {...defaultSettings}
        //ext={(now, previous) => console.log(`Next User Callback: Now displaying child ${now}. Previously displayed child ${previous}`)}
        //prev={(now, previous) => console.log(`Prev User Callback: Now displaying child ${now}. Previously displayed child ${previous}`)}
        //onChange={(now, previous) => console.log(`OnChange User Callback: Now displaying child ${now}. Previously displayed child ${previous}`)}

        // navButtonsProps={{style: {backgroundColor: 'cornflowerblue', borderRadius: 0}}}
        // navButtonsWrapperProps={{style: {bottom: '0', top: 'unset', }}}
        // indicatorContainerProps={{style: {margin: "20px"}}}
        // NextIcon='next'
      >
        <Banner
          number={0}
          image={WissensbausteinImage}
          text={
            <Box textAlign={"center"}>
              <Typography variant={"h6"}>Wissensbausteine</Typography>
              <Typography variant={"body1"}>
                Auf DiBEN finden Sie eine vielzahl an interessanten Lernvideos,
                welche Ihnen die verschiedenen Eigenschaften von Digitalen
                Geschäftsökosystemem näher bringen und Ihnen helfen Ihr eigenes
                Ecosystem weiterzuentwickeln.
              </Typography>
            </Box>
          }
        />
        <Banner
          number={0}
          image={PraxisbeispieleImage}
          text={
            <Box textAlign={"center"}>
              <Typography variant={"h6"}>Praxisnah</Typography>
              <Typography variant={"body1"}>
                Unsere Inhalte sind darauf ausgelegt praxisnah die Inhalte zu
                vermitteln. Deswegen untermauern wir unsere Lerninhalte mit
                einer Viezahl an Praxisbeispielen.{" "}
              </Typography>
            </Box>
          }
        />
        <Banner
          number={0}
          image={BenchmarkingToolImage}
          text={
            <Box textAlign={"center"}>
              <Typography variant={"h6"}>Benchmarking</Typography>
              <Typography variant={"body1"}>
                Unsere Plattform erlaubt es Ihnen, dass Sie regelmäßig ihre
                Ökosystemorientierung evaluieren und sich mit dem Markt
                vergleichen. Des weiteren können Sie ihre eigene Entwicklung
                beobachten.
              </Typography>
            </Box>
          }
        />
        <Banner
          number={0}
          image={VisualisierungstoolImage}
          text={
            <Box textAlign={"center"}>
              <Typography variant={"h6"}>Visualisierungstool</Typography>
              <Typography variant={"body1"}>
                Neben einer Vielzahl an anderen Tools haben Sie bei uns die
                Möglichkeit ihr eigenes Ökosystem zu modellieren und auf Basis
                dessen zu analyieren.
              </Typography>
            </Box>
          }
        />
      </Carousel>
    </div>
  );
};

const Banner = ({ image, text }) => {
  const theme = useTheme();

  return (
    <Box sx={{ p: theme.spacing(1) }}>
      <Paper
        sx={{
          width: "100%",
          aspectRatio: "2559/1401",
          backgroundImage: `url(${image})`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          display: "flex",
          flexDirection: "column",
          border: "1px solid grey",
        }}
      >
        <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            minHeight: "20%",
            background:
              "linear-gradient(0deg, rgba(0,0,0,0.9212477227219013) 0%, rgba(0,0,0,0.7111636891084558) 56%, rgba(0,0,0,0) 100%)",
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              color: "white",
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ m: theme.spacing(2) }}>{text}</Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

const Text = ({ title = undefined, content }) => {
  return (
    <div>
      {title ? (
        <Typography variant={"h5"} component={"h2"}>
          {title}
        </Typography>
      ) : (
        <></>
      )}
      <Typography variant={"body1"}>
        DiBEN wird Sie dabei unterstützen die benötigten Kompetenzen aufzubauen
        und eine Ökosystemperspektive einzunehmen, welche dabei hilft von dem
        Ökosystem und dessen Entwicklungen zu profitieren. Das geschieht zum
        einen durch wertvolles aufbereitetes Wissen. Dieses ist flexibel sowie
        effizient (z.B. Videos, Podcasts, Info-Beiträge) nutzbar und enthält
        bisher kaum zugängliche handlungsrelevante Einsichten und Empfehlungen.
        Zum anderen bietet DiBEN zu jedem Themenbereich auch passende digitale,
        neu entwickelte Tools. Die Nutzung dieser digitalen Tools soll dazu
        befähigen, selbstbestimmt ihr Ökosystem zu verstehen und
        weiterzuentwickeln.
      </Typography>
    </div>
  );
};
const Examples = () => {
  const ref = React.useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end end"],
  });

  const springYProgress = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });
  const theme = useTheme();
  return (
    <Box sx={{ maxWidth: "80rem", mx: "auto", p: theme.spacing(3) }}>
      <motion.div ref={ref} style={{ opacity: springYProgress }}>
        <Text
          title={"Was können Sie von DiBEN erwarten?"}
          content={
            "DiBEN wird Sie dabei unterstützen die benötigten Kompetenzen aufzubauen und eine Ökosystemperspektive einzunehmen, welche dabei hilft von dem Ökosystem und dessen Entwicklungen zu profitieren. Das geschieht zum einen vor allem durch wertvolles Wissen, welches heute nicht auf KMU-Kontexte ausgerichtet und fast ausschließlich in internationalen Forschungsjournalen existiert. Dieses ist flexibel sowie effizient (z.B. Videos, Podcasts, Info-Beiträge) nutzbar und enthält bisher kaum zugängliche handlungsrelevante Einsichten und Empfehlungen, z.B. zu Technologieauswahl und -gestaltung, Strategien oder konkreten Instrumenten des Managements von Partnerbeziehungen. Zum anderen bietet DiBEN zu jedem Themenbereich auch verbundene datenbasierte Tools, die im Rahmen des Projekts neu entwickelt wurden. Die Interaktion mit diesen digitalen Tools soll KMU-Führungskräfte befähigen, selbstbestimmt ihr Ökosystem zu verstehen und weiterzuentwickeln:"
          }
        />
        <CustomCarousel />
      </motion.div>
    </Box>
  );
};

export default Examples;
