import * as React from "react";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import { ReactComponent as Logo } from "../Images/Logo.svg";
import { Box, Container, Typography, useTheme } from "@mui/material";
import CoverImage from "../Images/CoverImage2.jpg";
import { useEffect, useRef } from "react";
import { setParentHeight } from "../scripts/scripts";

const LogoContainer = ({ springYProgress }) => {
  const theme = useTheme();

  const transformY = useTransform(
    springYProgress, // Map x from these values:
    [0, 1], // Into these values:
    ["0rem", "-18rem"],
  );
  return (
    <motion.div
      style={{
        translateX: transformY,
        margin: theme.spacing(2),
        //    transformOrigin: "0%",
      }}
    >
      <Container
        sx={{
          width: {
            xs: "7rem",
            sm: "10rem",
            md: "15rem",
            lg: "17em",
            xl: "18rem",
          },
          m: 0,
        }}
      >
        <Logo />
      </Container>
    </motion.div>
  );
};

const TextCard = ({ springYProgress }) => {
  const color = "#007AF0";
  const theme = useTheme();
  const transformY = useTransform(
    springYProgress, // Map x from these values:
    [0, 1], // Into these values:
    ["0vh", "100vh"],
  );
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 3 }}
      style={{ marginBottom: transformY }}
    >
      <Box
        sx={{
          textAlign: "center",
          margin: { xs: theme.spacing(1), sm: theme.spacing(4) },
        }}
      >
        <Typography
          sx={{ typography: { xs: "h6", sm: "h3", md: "h3" } }}
          component={"h1"}
        >
          Willkommen beim <br />
          <b style={{ color: color }}>Di</b>gital{" "}
          <b style={{ color: color }}>B</b>usiness{" "}
          <b style={{ color: color }}>E</b>cosystem{" "}
          <b style={{ color: color }}>N</b>avigator!
        </Typography>
        <Typography
          sx={{ typography: { xs: "caption", sm: "body1", md: "subtitle1" } }}
        >
          Wir freuen uns, dass Sie den Weg auf unsere Plattform gefunden haben!
          Bevor Sie tiefer in die Plattform und Inhalte einsteigen, möchten wir
          Ihnen einige wichtige Hintergrundinformationen an die Hand geben,
          welche verdeutlichen, warum das Thema digitale Geschäftsökosysteme
          auch für Sie relevant ist.
        </Typography>
      </Box>
    </motion.div>
  );
};
const BackGroundImage = React.forwardRef(({ children }, ref) => {
  return (
    <Box
      ref={ref}
      sx={{
        overflow: "hidden",
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: `url(${CoverImage})`,
        borderImage: "fill 0 linear-gradient(#0006,#FFF)",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      alt={"Illustration eines digitalen Geschäftsökosystems"}
    >
      {children}
    </Box>
  );
});

const Welcome = ({ backgroundColor = undefined }) => {
  const ref = React.useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });

  const springYProgress = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });
  const parentRef = useRef(null);
  useEffect(() => {
    const parentElement = ref.current;
    const children = parentElement.children;
    let maxHeight = 0;

    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      const childHeight = child.offsetTop + child.offsetHeight;
      if (childHeight > maxHeight) {
        maxHeight = childHeight;
      }
    }
    const imageMaxHeight = (window.innerWidth * 9) / 16

    parentElement.style.height = `${maxHeight > imageMaxHeight ? maxHeight : imageMaxHeight}px`;
  }, []);
  return (
    <section style={{ backgroundColor: backgroundColor }} ref={ref}>
      <BackGroundImage ref={parentRef}>
        <LogoContainer  springYProgress={springYProgress} />
        <Box sx={{ flexGrow: 1 }} />
        <TextCard springYProgress={springYProgress} />
      </BackGroundImage>
    </section>
  );
};
export default Welcome;
