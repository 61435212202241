import React from 'react';
import './App.css';
import {LandingPage} from "./Pages/LandingPage";
import {ThemeProvider} from "@mui/material";
import {theme} from "./theme";

function App() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <LandingPage/>
      </ThemeProvider>
    </div>
  );
}

export default App;
