import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { ReactComponent as DistralLogo } from "../Images/DistralLogo.svg";
import { ReactComponent as HessenLogo } from "../Images/HessenLogo.svg";
import React from "react";

function Impressum() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <Typography
        onClick={handleClickOpen()}
        sx={{ color: "white", cursor: "pointer" }}
      >
        Impressum
      </Typography>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Typography variant={"h4"}>Impressum</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant={"body2"}>
            Verantwortlich für den Inhalt dieser Website:
            <br />
            Universität Kassel
            <br />
            Mönchebergstraße 19
            <br />
            34109 Kassel
            <br />
            Deutschland
            <br />
            Telefon: +49 561 804-0
            <br />
            Fax: +49 561 804-2330
            <br />
            E-Mail: poststelle@uni-kassel.de
            <br />
            Die Universität Kassel ist eine Körperschaft des öffentlichen
            Rechts. Sie wird durch ihre
            <br />
            Präsidentin Prof. Dr. Ute Clement vertreten.
            <br />
            Inhaltlich verantwortlich für die Inhalte auf www.diben-hessen.de
            ist:
            <br />
            Institute of Management and Business Administration – Fachgebiet
            Management der digitalen
            <br />
            Transformation, Prof. Dr. André Hanelt
            <br />
            Henschelstraße 2<br />
            34127 Kassel
            <br />
            Tel.: 0561-804-3902
            <br />
            E-Mail: info@diben-hessen.de
            <br />
            Ansprechperson: Henrik Pohsner
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Schließen</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export const Footer = () => {
  const theme = useTheme();
  return (
    <footer style={{ backgroundColor: theme.palette.background.dark }}>
      <Box sx={{ maxWidth: "80rem", mx: "auto", p: theme.spacing(3) }}>
        <Grid
          container
          spacing={2}
          sx={{
            color: "white",
          }}
        >
          <Grid item xs={6} sm={6} md={3}>
            <Typography variant={"h4"} sx={{ mb: theme.spacing(2) }}>
              <b>DiBEN</b>
            </Typography>
            <Typography variant={"body1"}>
              <Impressum />
            </Typography>
          </Grid>
          <Grid item xs={0} sm={0} md={4} />
          <Grid item xs={6} sm={6} md={3}>
            <Typography variant={"h4"} sx={{ mb: theme.spacing(2) }}>
              <b>Gefördert von</b>
            </Typography>
            <a href={"https://digitales.hessen.de/foerderprogramme/distrl"}>
              <DistralLogo />
            </a>
            <a href={"https://digitales.hessen.de/"}>
              <HessenLogo />
            </a>

          </Grid>
        </Grid>
      </Box>
    </footer>
  );
};
export default Footer;
/*


 */