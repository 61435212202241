import * as React from "react";
import { useScroll, useSpring, useTransform } from "framer-motion";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import CoverImage from "../Images/CoverImage.jpg";
import { motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { setParentHeight } from "../scripts/scripts";

const Image = ({ springYProgress, height = 10 }) => {
  const theme = useTheme();
  const transformY = useTransform(
    springYProgress, // Map x from these values:
    [0, 1], // Into these values:
    ["45vw", "0vw"],
  );

  return (
    <motion.div
      transition={{ duration: 1, origin: 1 }}
      style={{
        position: "absolute",
        right: "9vw",
        top: "0px",
        marginTop: "5rem",
        translateX: transformY,
      }}
    >
      <Paper
        sx={{
          width: { xs: "80vw", sm: "80vw", md: "40vw", lg: "40vw", xl: "40vw" },
          display: { xs: "none", sm: "none", md: "block" },
          height: height,
          p: theme.spacing(1),
          backgroundImage: `url(${CoverImage})`,
          aspectRatio: "16/9",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></Paper>
    </motion.div>
  );
};

const Text = React.forwardRef(({ springYProgress }, ref) => {
  const theme = useTheme();
  const transformY = useTransform(
    springYProgress, // Map x from these values:
    [0, 1], // Into these values:
    ["-40vw", "0vw"],
  );

  return (
    <motion.div
      style={{
        position: "absolute",
        left: transformY,
        marginLeft: "9vw",
        top: "0px",
        marginTop: "2rem",
      }}
    >
      <Paper
        ref={ref}
        sx={{
          p: theme.spacing(1),
          width: { xs: "80vw", sm: "80vw", md: "40vw", lg: "40vw", xl: "40vw" },
        }}
      >
        <Typography component={"h2"} variant={"h5"}>
          Was sind digitale Geschäftsökosysteme und warum sind sie relevant für
          Sie?
        </Typography>

        <Typography
          sx={{ variant: { sm: "body2", md: "body1", lg: "h6" } }}
          component={"p"}
        >
          Ein digitales Geschäftsökosystem beschreibt das komplexe und
          digital-gestützte Beziehungsgeflecht von Unternehmen, vielfältigen
          Partnern sowie Kunden. Durch die digitale Transformation und
          zunehmende Vernetzung ist diese Perspektive wichtig für Unternehmen
          gleich welcher Branche und Größe. Letztlich bewegt sich jedes
          Unternehmen in einem oder mehreren Geschäftsökosystemen. Um
          Unternehmen dabei zu unterstützen von ihrem digitalen
          Geschäftsökosystem zu profitieren, haben wir den Digital Business
          Ecosystem Navigator ins Leben gerufen.
        </Typography>
      </Paper>
    </motion.div>
  );
});

const Relevance = () => {
  const [imageHeight, setImageHeight] = React.useState(0)

  const ref = React.useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end end"],
  });

  const springYProgress = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const parentRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    setParentHeight(parentRef, 5);
  }, []);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    console.log(windowSize);
    setParentHeight(parentRef, 5);
    setImageHeight(textRef.current.clientHeight)
  }, [windowSize]);
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section style={{ backgroundColor: '#E0F4FF' }} ref={ref}>
      <div
        style={{ position: "relative", maxWidth: "100vw", overflowX: "hidden" }}
        ref={parentRef}
      >
        <Image springYProgress={springYProgress} height={imageHeight} />

        <Text springYProgress={springYProgress} ref={textRef} />
      </div>
    </section>
  );
};
export default Relevance;
