/*
Centrierter Text anfang

dann 3 Boxen Icon linker Fly in nach einander mit Werteversprechen rechts daneben (fade in?)

dann letzer Text
 */

import {
  Box,
  Container,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";
import * as React from "react";
import { ReactComponent as OnlineLearning } from "../Images/OnlineLearning.svg";
import { ReactComponent as SelfLearn } from "../Images/SelfLearn.svg";
import { ReactComponent as Tools } from "../Images/Tools.svg";

const Text = ({ title = undefined, content }) => {
  const ref = React.useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["center end", "end end"],
  });

  const springYProgress = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });
  const transformY = useTransform(
    springYProgress, // Map x from these values:
    [0, 1], // Into these values:
    ["-100vw", "0vw"],
  );
  return (
    <div ref={ref}>
      <motion.div
        style={{
          opacity: springYProgress,
        }}
      >
        {title ? (
          <Typography variant={"h5"} component={"h2"}>
            {title}
          </Typography>
        ) : (
          <></>
        )}
        <Typography variant={"body1"}>{content}</Typography>
      </motion.div>
    </div>
  );
};
const BenefitBox = ({ icon, text }) => {
  const theme = useTheme();
  const ref = React.useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["center end", "end end"],
  });

  const springYProgress = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });
  const transformY = useTransform(
    springYProgress, // Map x from these values:
    [0, 1], // Into these values:
    ["-100vw", "0vw"],
  );

  return (
    <motion.div
      ref={ref}
      style={{
        width: "100%",
        left: "20vw",
        top: "0px",
        marginTop: "2rem",
        marginLeft: transformY,
      }}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Paper sx={{ height: "6rem", width: "6rem", p: theme.spacing(2) }}>
          {icon}
        </Paper>
        <Typography
          sx={{
            variant: { sm: "body2", md: "h5" },
            marginLeft: theme.spacing(2),
          }}
        >
          <b>{text}</b>
        </Typography>
      </Stack>
    </motion.div>
  );
};
export const Benefits = () => {
  const theme = useTheme();

  return (
    <section style={{ backgroundColor: "#E0F4FF" }}>
      <Box sx={{ maxWidth: "80rem", mx: "auto", p: theme.spacing(3) }}>
        <Text
          title={"Warum sollten Sie als KMU DiBEN nutzen?"}
          content={
            <>
              'Strukturen, Beziehungen, Technologien und Strategien in digitalen
              Geschäftsökosystemen können für etablierte Unternehmen aus nicht
              rein-digitalen Branchen eine Herausforderung sein, u.a. da
              bestimmte Kompetenzen aufgebaut werden müssen.
              <br />
              DiBEN unterstützt diesen Kompetenzerwerb durch den Zugang zu
              aktuellen und aufbereiteten wissenschaftlichen Erkenntnissen, Best
              Practices und praktischen Tools. Diese Kompetenzen können dann in
              die Managementprozesse eingebracht werden und damit in
              Unternehmensentscheidungen. Der Kompetenzaufbau ist insbesondere
              wichtig um zu verhindern, dass andere, vornehmlich digitale
              Akteure, Ökosysteme gestalten und deren Ausrichtung bestimmen.'
            </>
          }
        />
        <Box sx={{ p: theme.spacing(1), pl: theme.spacing(3) }}>
          <BenefitBox
            icon={
              <OnlineLearning style={{ width: "inherit", height: "inherit" }} />
            }
            text={
              "Zugang zu aktuellen Erkenntnissen aus der Wissenschaft – verständlich und für Sie nutzbar aufbereitet"
            }
          />
          <BenefitBox
            icon={<SelfLearn style={{ width: "inherit", height: "inherit" }} />}
            text={
              "Self-Learning Möglichkeit für alle Mitarbeitende, damit alle Bereiche Ihres Unternehmens von dem Wissen profitieren können"
            }
          />
          <BenefitBox
            icon={<Tools style={{ width: "inherit", height: "inherit" }} />}
            text={
              "Digitale Tools mit denen Sie das Wissen im Unternehmen anwenden können "
            }
          />
        </Box>
      </Box>
    </section>
  );
};
