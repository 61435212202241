function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

export const setParentHeight = (parentRef, extraRem=0) => {
    const parentElement = parentRef.current;
    const children = parentElement.children;
    let maxHeight = 0;

    for (let i = 0; i < children.length; i++) {
        const child = children[i];
        const childHeight = child.offsetTop + child.offsetHeight;
        if (childHeight > maxHeight) {
            maxHeight = childHeight;
        }
    }

    parentElement.style.height = `${maxHeight +convertRemToPixels(extraRem)/2}px`;

}