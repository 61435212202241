import { createTheme } from '@mui/material/styles';
import * as Montserrat from '@fontsource/montserrat'
export const theme = createTheme({
    palette: {
        primary: {
            main: '#074ad9',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#dd00ff',
        },
        background: {
            default: '#eeeeee',
            dark: '#0E234D'
        },
    },
    status: {
        danger: 'orange',
    },
    typography: {
        fontFamily: 'Montserrat, Roboto, Helvetic, Arial , sans-serif',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
        @font-face {
          font-family: 'Montserrat';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Raleway'), local('Raleway-Regular'), url(${Montserrat}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
        },
    },

})
